import firebase from "firebase";

const config = {
  apiKey: "AIzaSyA4Bm_im24KEv_h-X0dni5cGvIbYpzWjxg",
  authDomain: "ga-bigquery-209413.firebaseapp.com",
  databaseURL: "https://ga-bigquery-209413.firebaseio.com",
  projectId: "ga-bigquery-209413",
  storageBucket: "ga-bigquery-209413.appspot.com",
  messagingSenderId: "58419644458",
  appId: "1:58419644458:web:4f1129d30dabd0dd469b53",
};
firebase.initializeApp(config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export default firebase;


export const pointColors = [
  "#ef5350",
  "#ec407a",
  "#ab47bc",
  "#7e57c2",
  "#5c6bc0",
  "#42a5f5",
  "#29b6f6",
  "#26c6da",
  "#26a69a",
  "#66bb6a",
  "#9ccc65",
  "#d4e157",
  "#ffee58",
  "#ffca28",
  "#ffa726",
  "#ff7043"
]

export const logoutSVGPath1 = "M18.9071 12.2776L15.8823 9.09352C15.6877 8.88989 15.3693 9.03798 15.3693 9.35268V11.4075H11.3715C11.1592 11.4075 11 11.5926 11 11.7963V13.2032C11 13.4253 11.1769 13.5919 11.3715 13.5919H15.3693V15.6652C15.3693 15.9614 15.6877 16.1095 15.8823 15.9059L18.9071 12.7404C19.031 12.6293 19.031 12.4071 18.9071 12.2776Z"
export const logoutSVGPath2 = "M13.7006 15.7436H8.82744C8.51143 15.7436 8.26195 15.4947 8.26195 15.1795V9.80392C8.26195 9.48869 8.51143 9.23982 8.82744 9.23982H13.7006C13.8669 9.23982 14 9.10709 14 8.94118V7.29864C14 7.13273 13.8669 7 13.7006 7H6.71518C6.33264 7 6 7.28205 6 7.63047V17.3695C6 17.7179 6.31601 18 6.71518 18H13.7006C13.8669 18 14 17.8673 14 17.7014V16.0588C14 15.8763 13.8503 15.7436 13.7006 15.7436Z"
